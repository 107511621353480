#nprogress .bar {
    background: #dc7227 !important;
    height: 3px;
}

#nprogress .peg {
    box-shadow: 0 0 10px #dc7227, 0 0 5px #dc7227 !important;
}

#nprogress .spinner-icon {
    border-top-color: #dc7227 !important;
    border-left-color: #dc7227 !important;

    width: 30px;
    height: 30px;
}

#nprogress .spinner {
    top: 15px;
    left: 15px;
}